/* The side navigation menu */
.sidebar {
  
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  
  overflow: auto;
}
.sidebar img{
  margin-top:70px;
}
.menutoggle{
  display:none;
}
/* Sidebar links */
.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

/* Active/current link */
.sidebar a.active {
  color: black;
}

/* Links on mouse-over */
.sidebar a:hover{
  background-color: #555;
  color: white;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}


/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 738px) {
  .sidebar {
    display:none;
  }
 .menutoggle{
    display:block;
    position:fixed;
    width:100%;
    background:white;
    z-index:99;
  }
 
  .navbar-color{
    background-color:#DCDCDC	
  } 
  
}